<template>
  <div class="myLesson">
    <div class="nav-btn">
      <router-link :to="{path:'/lesson/invite',query: {admin_kf_id: admin_kf_id,offline_id:offline_id}}" class="active"
        ><span>客服邀约</span></router-link
      >
      <router-link :to="{path:'/lesson/myInvited',query: {admin_kf_id: admin_kf_id,offline_id:offline_id}}"
        ><span>邀约记录</span></router-link
      >
    </div>
    <van-tabs v-model="active" class="van-tabs" >
      <van-tab
        v-for="(lesson, index) in list"
        :key="index"
        :title="lesson.cate_name"
      >
        <div class="container">
          <div class="list" v-for="(item, j) in lesson.list" :key="j">
            <h4>{{ item.bookname }}</h4>
            <div class="main">
              <div class="inner">
                <p>
                  课程价格：
                  <span class="gold-num">{{ item.price }}</span>
                </p>
                <div>
                  <!-- <button
                    class="btn-default"
                    @click="
                      getDouyinUrl(
                        item.id,
                        item.images,
                        item.bookname,
                        item.price
                      )
                    "
                  >
                    邀约二维码
                  </button> -->
                  <button
                    class="btn-success"
                    @click="createTrill($event, item.id)"
                  >
                    邀约链接
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>

    <!-- 二维码 -->
    <mt-popup v-model="qrcodeVisible" position="center" class="qr-img">
      <i class="jym-close dialog-close" @click="qrcodeVisible = false"></i>
      <img :src="qrcodeImage" />
    </mt-popup>

    <!-- 二维码 -->
    <mt-popup v-model="douyinVisible" position="center" class="douyin">
      <i class="jym-close dialog-close" @click="douyinVisible = false"></i>
      <div class="wrap" id="html2canvas" ref="html2canvas">
        <img class="bg" :src="qrImage" />
        <div class="text text1">{{ bookname }}</div>
        <div class="text text2">课程价格：{{ lessonPrice }}</div>
        <div class="text text3">长按识别二维码报名密训课程</div>
        <div class="qrcode" id="qrcode" ref="qrCodeUrl"></div>
      </div>
      <div class="mark-img">
        <van-loading type="spinner" color="#1989fa" style="margin-top: 100px">加载中...</van-loading>
      </div>
      <img class="canvas-img" v-if="canvasUrl" :src="canvasUrl" />
    </mt-popup>
  </div>
</template>

<script>
import Vue from "vue";
import { Tab, Tabs } from "vant";
import { Loading } from 'vant';
import html2canvas from "html2canvas";
import { offlineList } from "@api/public";
import QRCode from "qrcodejs2";
import clipboard from "@utils/clipboard";
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Loading);
export default {
  data() {
    return {
      active: 0,
      admin_kf_id: "",
      offline_id: "",
      qrImage: "",
      qrcodeImage: "",
      bookname: "",
      canvasUrl: "",
      list: [],
      qrcodeVisible: false,
      douyinVisible: false,
    };
  },
  created() {
    this.admin_kf_id = this.$route.query.admin_kf_id;
    this.offline_id = this.$route.query.offline_id;
    if (this.admin_kf_id == 0) {
      this.$dialog.error("暂无权限，两秒后返回！");
      setTimeout(() => {
        this.$router.push("/user");
      }, 2000);
    }
    this.getList();
  },
  methods: {
    getList() {
      offlineList(this.offline_id)
        .then((res) => {
          this.$set(this, "list", res.data);
        })
        .catch((err) => {
          this.$dialog.error(err.msg);
        });
    },

    // 邀约二维码
    getDouyinUrl(id, img, bookname, price) {
      let that = this;
      that.canvasUrl = "";
      that.qrImage = img;
      that.bookname = bookname;
      that.lessonPrice = price;
      that.douyinVisible = true;
      let url =
        location.origin + `/lesson/offline/${id}?kf_id=${that.admin_kf_id}`;
      // that.createCode(url);
      that.createImage(url)
    },

    // 生成二维码
    createCode(url) {
      let that = this;
      document.getElementsByClassName("qrcode")[0].innerHTML = "";
      new QRCode(this.$refs.qrCodeUrl, {
        text: url, // 需要转换为二维码的内容
        width: 80,
        height: 80,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },

    // 生成抖音二维码
    async createImage(url) {
      await this.createCode(url);
      html2canvas(this.$refs.html2canvas, {
        backgroundColor: null,
        useCORS: true,
      }).then((canvas) => {
        this.canvasUrl = canvas.toDataURL("image/png");
      });
    },

    // 邀约链接
    createTrill(event, id) {
      const url =
        location.origin + `/lesson/offline/${id}?kf_id=${this.admin_kf_id}`;
      clipboard(url, event);
      this.$dialog.success("复制成功！");
    },
  },
};
</script>

<style lang="scss" scoped>
.myLesson {
  padding-bottom: 1rem;
  .nav-btn {
    background: white;
    height: 0.88rem;
    font-size: 0.26rem;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    a {
      width: 50%;
      text-align: center;
      line-height: 0.88rem;
    }
    .active {
      color: #26a2ff;
      border-bottom: 2px solid #26a2ff;
    }
  }
   .van-tabs {
    margin-top: 0.88rem;
    ::v-deep .van-tabs__wrap {
      position: fixed;
      left: 0;
      right: 0;
       z-index: 999;
    }
    
  }
  .container {
    width: 7.1rem;
    margin: 0.2rem 0.2rem 0;
    border-radius: 0.12rem;
    padding-top:0.88rem;
    .list {
      width: 100%;
      padding: 0.1rem;
      background: white;
      margin-top: 0.2rem;
      h4 {
        font-size: 0.28rem;
        padding: 0.1rem 0 0.1rem 0;
      }
      .main {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0.1rem;
        .inner {
          display: flex;
          flex-flow: flex;
          justify-content: space-between;
          width: 100%;
          margin-top: 0.1rem;
          p {
            font-size: 0.26rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #656565;
          }
          > div {
            text-align: right;
            button {
              height: 0.42rem;
              font-size: 0.22rem;
              color: white;
              border-radius: 0.04rem;
              padding: 0 0.3rem;
              margin-right: 0.2rem;
            }
          }
        }
      }
      .gold {
        border: none;
        background: black;
        color: white;
      }
    }
  }
  .qr-img {
    width: 6.5rem;
    background: initial;
    img {
      width: 100%;
    }
  }
  .douyin {
    width: 300px;
    height: 320px;
    .wrap {
      position: relative;
      width: 300px;
      height: 320px;
      .bg {
        width: 100%;
      }
      .qrcode {
        position: absolute;
        bottom: 50px;
        right: 20px;
        img {
          background-color: #fff;
          padding: 6px;
          box-sizing: border-box;
        }
      }
      .text {
        position: absolute;
        overflow: hidden;
        line-height: 0.5rem;
        font-size: 0.26rem;
        width: 200px;
        text-align: left;
        padding-left: 0.2rem;
        font-weight: 700;
      }
      .text1 {
        bottom: 90px;
        line-height: 20px;
      }
      .text2 {
        bottom: 60px;
        color: #daa070;
      }
      .text3 {
        bottom: 0;
        height: 50px;
        line-height: 50px;
        text-align: center;
        width: 100%;
        color: #b0b0b0;
        letter-spacing: 2px;
        padding: 0;
      }
    }
    .canvas-img {
      width: 300px;
      height: 320px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;
    }
    .mark-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 300px;
      height: 320px;
      background: white;
      z-index: 10;
      text-align: center;
    }
  }
}
</style>